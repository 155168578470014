<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('component_settings.company_history') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
          <b-form  @submit.prevent="handleSubmit(searchData)" >
            <b-row>
              <b-col lg="6" sm="6" md="6">
                <ValidationProvider name="Bin No" vid="bin_no" rules="">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="bin_no"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('li_step.bin_no')}}
                  </template>
                  <b-form-input
                      id="file_no"
                      v-model="search.file_no"
                      :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col class="col-sm-2">
                <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('component_settings.company_history') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(organization)="data">
                      {{ getOrgName(data.item.org_id) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status === 1 ? $t('globalTrans.active') : $t('globalTrans.inactive') }}
                    </template>
                    <template v-slot:cell(api_type)="data">
                      {{ getApiType(data.item.api_type) }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button title="Details" v-b-modal.modal-5 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="fas fa-eye"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Details :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import FormV from './Form'
import Details from './Details'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { daeBinCompanyAttachmentList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import commonAuth from '../CommonAuth'

export default {
  mixins: [ModalBaseMasterList, commonAuth],
  components: {
    ValidationObserver,
    ValidationProvider,
    FormV,
    Details
  },
  data () {
    return {
      search: {
        file_no: '',
        application_id: ''
      },
      serviceList: []
    }
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('component_settings.company_history') + ' ' + this.$t('globalTrans.entry') : this.$t('component_settings.company_history') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('li_step.bin_no'), class: 'text-left' },
        { label: this.$t('foreignCompany.organization_name'), class: 'text-left' },
        { label: this.$t('boro_import.company_address'), class: 'text-left' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'file_no' },
          { key: 'org_name_bn' },
          { key: 'org_address_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'file_no' },
          { key: 'org_name' },
          { key: 'org_address' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    'search.org_id': function (newVal) {
      this.serviceList = this.getServiceList(newVal)
    },
    State: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.search.org_id = this.userCheck()
    this.loadData()
  },
  methods: {
    getApiType (apiType) {
      const tmp = this.apiList.find(item => item.value === apiType)
      if (typeof tmp !== 'undefined') {
        return tmp.text
      } else {
        return ''
      }
    },
    getServiceList (orgId) {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
    },
    searchData () {
      this.loadData()
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(licenseRegistrationServiceBaseUrl, daeBinCompanyAttachmentList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const listData = data.map(item => {
        const serviceObj = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(rg => rg.value === item.service_id)
        const serviceName = {
           service_name: serviceObj !== undefined ? serviceObj.text_en : '',
           service_name_bn: serviceObj !== undefined ? serviceObj.text_bn : ''
         }
        return Object.assign({}, item, serviceName)
      })
      return listData
    },
    getOrgName (orgId) {
      const tmpOrg = this.organizationList.find(org => org.value === orgId)
      return this.$i18n.locale === 'en' ? tmpOrg !== undefined ? tmpOrg.text_en : '' : tmpOrg !== undefined ? tmpOrg.text_bn : ''
    }
  }
}
</script>
