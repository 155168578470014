<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12" id="form">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:body>
                <b-row>
                  <div class="col-md-12">
                    <table class="table" style="padding-left: 15px;padding-right: 15px">
                      <tr>
                        <th>{{ $t('li_step.bin_no') }}</th>
                        <td> {{ formData.file_no }}</td>
                        <th></th>
                        <td></td>
                      </tr>
                      <tr>
                        <th>{{ $t('foreignCompany.organization_name') }}</th>
                        <td>{{ ($i18n.locale=='bn') ? formData.org_name_bn : formData.org_name }}</td>
                        <th>{{ $t('boro_import.company_address') }}</th>
                        <td>{{ ($i18n.locale=='bn') ? formData.org_address_bn : formData.org_address }}</td>
                      </tr>
                    </table>
                  </div>
                </b-row>
                <b-row v-if="formData.all_application.length > 0">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('globalTrans.application_information')}}</legend>
                      <table class="table" style="padding-left: 15px;padding-right: 15px">
                        <thead class="thead">
                            <tr>
                              <th style="width:10%">{{$t('globalTrans.sl_no')}}</th>
                              <th style="width:15%">{{$t('service_name.service_name')}}</th>
                              <th style="width:15%">{{$t('li_step.application_id')}}</th>
                              <th style="width:15%">{{$t('li_step.license_no')}}</th>
                              <th style="width:15%">{{$t('externalLrcpn.type')}}</th>
                              <th style="width:15%">{{$t('externalLrcpn.issue_date')}}</th>
                              <th style="width:15%">{{$t('externalLrcpn.expired_date')}}</th>
                            </tr>
                            <tr v-for="(application, index) in formData.all_application" :key="index">
                              <td>{{ $n(index + 1) }}</td>
                              <td>{{ getColumnName($store.state.licenseRegistration.commonObj.serviceNamesList, application.service_id) }}</td>
                              <td>{{ application.application_id }}</td>
                              <td>
                                <slot v-if="application.service_id === 5">IMP_</slot>
                                <slot v-else-if="application.service_id === 6"> M_</slot>
                                <slot v-else-if="application.service_id === 23">S_</slot>
                                {{ application.generate_id }}
                              </td>
                              <td>
                                <span class="badge pay-status badge-success" v-if="application.type === 1">
                                  {{ $t('globalTrans.new') }}
                                </span>
                                <span class="badge pay-status badge-info" v-else>
                                  {{ $t('globalTrans.renew') }}
                                </span>
                              </td>
                              <td>{{ application.issue_date | dateFormat }}</td>
                              <td>{{ application.expire_date | dateFormat }}</td>
                          </tr>
                        </thead>
                      </table>
                    </fieldset>
                  </div>
                </b-row>
                <b-row>
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('component_settings.history_attachment')}}</legend>
                      <table class="table" style="padding-left: 15px;padding-right: 15px">
                        <thead class="thead">
                          <tr>
                            <th style="width:30%">{{$t('component_settings.history_attachment')}}</th>
                            <th style="width:30%">{{$t('globalTrans.attachment')}}</th>
                          </tr>
                        </thead>
                        <tr v-for="(company_attachment, index) in formData.company_attachments" :key="index">
                          <td>
                            {{ ($i18n.locale=='bn') ? company_attachment.company_attachment_bn : company_attachment.company_attachment }}
                          </td>
                          <td>
                            <b v-if="company_attachment.pre_attachment">
                              (<a target = '_blank' :href="licenseRegistrationServiceBaseUrl + 'storage/' + company_attachment.pre_attachment" download>{{$t('globalTrans.view_download')}}</a> )
                            </b>
                          </td>
                        </tr>
                        <template v-if="formData.company_attachments.length === 0">
                          <tr>
                            <th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                          </tr>
                        </template>
                      </table>
                    </fieldset>
                  </div>
                </b-row>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'

export default {
  props: ['id'],
  data () {
    return {
      formData: {
        service_id: 0,
        application_id: '',
        file_no: '',
        org_name: '',
        org_name_bn: '',
        org_address: '',
        org_address_bn: '',
        company_attachment_remove: [],
        company_attachments: [
          {
            name: '',
            name_bn: '',
            attachement: ''
          }
        ]
      },
      loading: false,
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      serviceList: []
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getformData()
      const atData = tmp.company_attachments.map(item => {
        const fileData = {}
        fileData.pre_attachment = item.attachment
        return Object.assign({}, item, fileData)
      })
      tmp.company_attachments = atData
      tmp.company_attachment_remove = []
      this.formData = tmp
    }
  },
  methods: {
    getColumnName (list, groupId, typeIt = null) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (typeIt) {
          return obj.text_bn
        }
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    getformData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
